// @ is an alias to /src
import HeaderImg from '@/components/home/HeaderImg.vue'

export default {
	name: 'Contact',
	components: {
		HeaderImg,
	},
	data: () => {
		return {
			contactList: [
				{
					icon: 'wecat',
					type: '联系人微信',
					link: '',
				},
				{
					icon: 'mail',
					type: '联系人邮箱',
					link: '',
				},
				{
					icon: "tel",
					type: "联系人电话",
					link: "",
				},
				{
					icon: 'wePub',
					type: '',
					link: '',
				},
			],
		};
	},

}
